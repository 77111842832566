<template>
  <div id="main">
    <div class="inner">
      <h1>Join Our Business Directory</h1>
      <div class="col-8"><span class="image main"><img src="../images/get-added.jpg" alt="join our business directory" /></span></div>
      <p class="justified">Are you an AAPI business owner? Does your business have an online store? If so, we would love to include your business to our directory! Please fill out the form below and expect to be added one week following submission. Know of an AAPI business and would like to submit on their behalf? Please do! Fill out the same form below and simply select “No” for “Are you the business owner?”</p>
      <a class="button primary" href="https://forms.gle/9br7hAq6dyAuATUM7" target="_blank">Fill Out Form</a> 
    </div>
  </div>
</template>

<script>

export default {
  name: 'GetAdded',
  
  computed: {
  }
}
</script>

<style scoped>
/*.row > .col-8 {
  margin: auto;
}
.row > * {
  padding: 0;
  text-align: center;
}
.justified {
  text-align: justify;
  text-align-last: center;
}*/
</style>