<template>
  <footer id="footer">
    <div class="inner">
      <section>
        <h2>Get in touch</h2>
        <!-- <form method="post" action="#">
          <div class="fields">
            <div class="field half">
              <input type="text" name="name" id="name" placeholder="Name" />
            </div>
            <div class="field half">
              <input type="email" name="email" id="email" placeholder="Email" />
            </div>
            <div class="field">
              <textarea name="message" id="message" placeholder="Message"></textarea>
            </div>
          </div>
          <ul class="actions">
            <li><input type="submit" value="Send" class="primary" /></li>
          </ul>
        </form> -->
        <p>Please contact us if you have any questions or comments. Connect with us on instagram, or send an email. We update and deploy our website multiple times a week. If you have contributed a business to us, you can expect it to be included within one week.</p>
      </section>
      <section>
        <h2>Connect</h2>
        <ul class="icons">
          <li><a href="https://www.instagram.com/aapibusinessco/" class="icon brands style2 fa-instagram" target="_blank"><span class="label">Instagram</span></a></li>
          <li><a href="mailto:aapibusiness.co@gmail.com" class="icon solid style2 fa-envelope"><span class="label">Email</span></a></li>
        </ul>
      </section>
      <ul class="copyright">
        <li>&copy; {{ year }} Asian American and Pacific Islander Business Collective. All rights reserved</li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  
  data() {
    return {
      year: new Date().getFullYear(),
    }
  }
}
</script>