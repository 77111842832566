<template>
  <div id="main">
    <div class="inner">
      <div class="row">
        <div class="col-8">
          <h1>{{ shop.name }}</h1>
          <p><b>Visit: <a :href="shop.website" target="_blank">{{ shop.website }}</a></b></p>
          <p class="justified">{{ shop.description }}</p>
          <span class="image fit"><img v-if="shop.banner" :src="require(`../images/${shop.banner}`)" alt=""/></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import json from '../../data.json'

export default {
  name: 'Shop',
  
  data() {
    return {
      articles: json
    }
  },
  
  computed: {
    shop: function () {
      return this.articles.filter(i => i.slug === this.$route.path.split('/').pop())[0]
    }
  }
}
</script>

<style scoped>
.row > .col-8 {
  margin: auto;
}
.row > * {
  padding: 0;
  text-align: center;
}
.justified {
  text-align: justify;
  text-align-last: center;
}
</style>