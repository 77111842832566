import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from './components/HelloWorld.vue'
import About from './components/About.vue'
import Shop from './components/Shop.vue'
import GetAdded from './components/GetAdded.vue'

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'HelloWorld',
      component: HelloWorld,
    },
    {
      path: '/about',
      name: 'About',
      component: About,
    },
    {
      path: '/shop/*',
      name: 'Shop',
      component: Shop,
    },
    {
      path: '/get-added',
      name: 'GetAdded',
      component: GetAdded,
    },
  ],
});