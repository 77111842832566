<template>
  <nav id="menu">
    <div class="inner">
      <h2>Menu</h2>
      <ul>
        <router-link
          v-for="routes in links"
          v-bind:key="routes.id"
          :to="`${routes.page}`"
          tag="li">
            <a v-on:click="toggleMenu()">{{routes.text}}</a>
        </router-link>
      </ul>
    </div>
    <a class="close" id="nav-icon" v-on:click="toggleMenu()" >Close</a>
  </nav>
</template>

<script>

export default {
  name: 'Navigation',
  
  data() {
    return {
      links: [
        {
          id: 0,
          text: 'Home',
          page:'/'
        },
        {
          id: 1,
          text: 'About',
          page:'/about'
        },
        {
          id: 2,
          text: 'Get Added',
          page:'/get-added'
        }
      ]
    }
  },
  
  mounted() {
    const mainPage = document.querySelector('#main')
    const body = document.body
    mainPage.addEventListener('click', function() {
      body.classList.remove('is-menu-visible')
    });
  },
  
  methods: {
    toggleMenu() {
      const body = document.body
      body.classList.toggle('is-menu-visible')
    },
  }
}
</script>

<style scoped>
</style>