<template>
  <header id="header">
    <div class="inner">
      <!-- Logo -->
      <a href="/" class="logo">
        <span class="symbol"><img src="../images/logo.png" alt="" /></span>
        <span class="title">Asian American and Pacific Islander Business Collective</span>
      </a>

      <!-- Nav -->
      <nav>
        <ul>
          <li>
            <a id="nav-icon"
              v-on:click="toggleMenu()"
            >
              Menu
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  
  props: {
  },
  
  methods: {
    toggleMenu() {
      const body = document.body
      body.classList.toggle('is-menu-visible')
    },
  }
}
</script>