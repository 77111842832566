<template>
  <div id="main">
    <div class="inner">
      <header>
        <h1>Shop with a purpose, shop small.</h1>
        <!-- <p class="align-right update-highlight"><sub>We added 6 new stores! 02-25-2021</sub></p> -->
        <p>AAPI Business Collective is a directory for businesses owned by Asian Americans and Pacific Islanders. It is a platform for those who wish to support and shop from AAPI businesses. Categories range from Apparel to Wellness.</p>
      </header>
      <directory-base/>
    </div>
  </div>
</template>

<script>
import DirectoryBase from './DirectoryBase.vue'

export default {
  name: 'HelloWorld',
  
  components: {
    DirectoryBase
  }
}
</script>

<style scoped>
.update-highlight {
   color: #1c305c;
}
</style>
