<template>
  <div class="row">
    <!-- <categories-list 
      :main-class="'col-3'"
    /> -->
    <div 
      :class=mainClass
    >
      <!-- Jewelry & Accessory -->
      <h2 v-if="jewelryAccessory.length" id="jewelry-accessory">Jewelry & Accessory</h2>
      <section v-if="jewelryAccessory.length" class="tiles">
        <article 
          v-for="article in jewelryAccessory"
          v-bind:key="article.id"
          @click="handleClick(article)"
        >
          <span class="image">
            <img v-if="article.thumbnail" :src="require(`../images/${article.thumbnail}`)" :alt="article.name" />
            <img v-else src="../images/placeholder.png" :alt="article.name" />
          </span>
          <a :href="'/shop/' + article.slug">
            <h2>{{ article.name }}</h2>
          </a>
        </article>
      </section>
      <!-- Apparel -->
      <h2 v-if="apparel.length" id="apparel">Apparel</h2>
      <section v-if="apparel.length" class="tiles">
        <article 
          v-for="article in apparel"
          v-bind:key="article.id"
        >
          <span class="image">
            <img v-if="article.thumbnail" :src="require(`../images/${article.thumbnail}`)" :alt="article.name" />
            <img v-else src="../images/placeholder.png" :alt="article.name" />
          </span>
          <a :href="'/shop/' + article.slug">
            <h2>{{ article.name }}</h2>
          </a>
        </article>
      </section>
      <!-- Art & Stationery -->
      <h2 v-if="artStationary.length" id="art-stationary">Art & Stationery</h2>
      <section v-if="artStationary.length" class="tiles">
        <article 
          v-for="article in artStationary"
          v-bind:key="article.id"
          @click="handleClick(article)"
        >
          <span class="image">
            <img v-if="article.thumbnail" :src="require(`../images/${article.thumbnail}`)" :alt="article.name" />
            <img v-else src="../images/placeholder.png" :alt="article.name" />
          </span>
          <a :href="'/shop/' + article.slug">
            <h2>{{ article.name }}</h2>
          </a>
        </article>
      </section>
      <!-- Food & Drink -->
      <h2 v-if="foodDrink.length" id="food-drink">Food & Drink</h2>
      <section v-if="foodDrink.length" class="tiles">
        <article 
          v-for="article in foodDrink"
          v-bind:key="article.id"
          @click="handleClick(article)"
        >
          <span class="image">
            <img v-if="article.thumbnail" :src="require(`../images/${article.thumbnail}`)" :alt="article.name" />
            <img v-else src="../images/placeholder.png" :alt="article.name" />
          </span>
          <a :href="'/shop/' + article.slug">
            <h2>{{ article.name }}</h2>
          </a>
        </article>
      </section>
      <!-- Home -->
      <h2 v-if="home.length" id="category-home">Home</h2>
      <section v-if="home.length" class="tiles">
        <article 
          v-for="article in home"
          v-bind:key="article.id"
          @click="handleClick(article)"
        >
          <span class="image">
            <img v-if="article.thumbnail" :src="require(`../images/${article.thumbnail}`)" :alt="article.name" />
            <img v-else src="../images/placeholder.png" :alt="article.name" />
          </span>
          <a :href="'/shop/' + article.slug">
            <h2>{{ article.name }}</h2>
          </a>
        </article>
      </section>
      <!-- Self Care -->
      <h2 v-if="selfCare.length" id="self-care">Self Care</h2>
      <section v-if="selfCare.length" class="tiles">
        <article 
          v-for="article in selfCare"
          v-bind:key="article.id"
          @click="handleClick(article)"
        >
          <span class="image">
            <img v-if="article.thumbnail" :src="require(`../images/${article.thumbnail}`)" :alt="article.name" />
            <img v-else src="../images/placeholder.png" :alt="article.name" />
          </span>
          <a :href="'/shop/' + article.slug">
            <h2>{{ article.name }}</h2>
          </a>
        </article>
      </section>
      <!-- Kids -->
      <h2 v-if="kids.length" id="kids">Kids</h2>
      <section v-if="kids.length" class="tiles">
        <article 
          v-for="article in kids"
          v-bind:key="article.id"
          @click="handleClick(article)"
        >
          <span class="image">
            <img v-if="article.thumbnail" :src="require(`../images/${article.thumbnail}`)" :alt="article.name" />
            <img v-else src="../images/placeholder.png" :alt="article.name" />
          </span>
          <a :href="'/shop/' + article.slug">
            <h2>{{ article.name }}</h2>
          </a>
        </article>
      </section>
      <!-- Pets -->
      <h2 v-if="pets.length" id="pets">Pets</h2>
      <section v-if="pets.length" class="tiles">
        <article 
          v-for="article in pets"
          v-bind:key="article.id"
          @click="handleClick(article)"
        >
          <span class="image">
            <img v-if="article.thumbnail" :src="require(`../images/${article.thumbnail}`)" :alt="article.name" />
            <img v-else src="../images/placeholder.png" :alt="article.name" />
          </span>
          <a :href="'/shop/' + article.slug">
            <h2>{{ article.name }}</h2>
          </a>
        </article>
      </section>
      <!-- Health & Wellness -->
      <h2 v-if="healthWellness.length" id="health-wellness">Health & Wellness</h2>
      <section v-if="healthWellness.length" class="tiles">
        <article 
          v-for="article in healthWellness"
          v-bind:key="article.id"
          @click="handleClick(article)"
        >
          <span class="image">
            <img v-if="article.thumbnail" :src="require(`../images/${article.thumbnail}`)" :alt="article.name" />
            <img v-else src="../images/placeholder.png" :alt="article.name" />
          </span>
          <a :href="'/shop/' + article.slug">
            <h2>{{ article.name }}</h2>
          </a>
        </article>
      </section>
      <!-- Professional Service -->
      <h2 v-if="professionalService.length" id="professional-service">Professional Service</h2>
      <section v-if="professionalService.length" class="tiles">
        <article 
          v-for="article in professionalService"
          v-bind:key="article.id"
          @click="handleClick(article)"
        >
          <span class="image">
            <img v-if="article.thumbnail" :src="require(`../images/${article.thumbnail}`)" :alt="article.name" />
            <img v-else src="../images/placeholder.png" :alt="article.name" />
          </span>
          <a :href="'/shop/' + article.slug">
            <h2>{{ article.name }}</h2>
          </a>
        </article>
      </section>
    </div>
  </div>
</template>

<script>
import json from '../../data.json'
// import CategoriesList from './CategoriesList.vue'

export default {
  name: 'Directory',

  components: {
    // CategoriesList
  },

  props: {
    mainClass: {
      type: String,
      default: 'col-12'
    },
  },
  
  data() {
    return {
      articles: json
    }
  },
  
  computed: {
    apparel: function () {
      return this.articles.filter(i => i.category === 'apparel')
    },
    jewelryAccessory: function () {
      return this.articles.filter(i => i.category === 'jewelry-accessory')
    },
    artStationary: function () {
      return this.articles.filter(i => i.category === 'art-stationary')
    },
    foodDrink: function () {
      return this.articles.filter(i => i.category === 'food-drink')
    },
    home: function () {
      return this.articles.filter(i => i.category === 'home')
    },
    kids: function () {
      return this.articles.filter(i => i.category === 'kids')
    },
    selfCare: function () {
      return this.articles.filter(i => i.category === 'self-care')
    },
    pets: function () {
      return this.articles.filter(i => i.category === 'pets')
    },
    healthWellness: function () {
      return this.articles.filter(i => i.category === 'health-wellness')
    },
    professionalService: function () {
      return this.articles.filter(i => i.category === 'professional-service')
    }
  },
}
</script>

<style scoped>
h2 {
  margin: 2em;
  text-align: center;
}
</style>