<template>
  <div id="main">
    <div class="inner">
      <div class="row">
        <div class=col-8>
          <h1 class="align-center">Who We Are</h1>
          <p><b>AAPI Business Collective</b> is a directory for businesses owned by Asian Americans and Pacific Islanders. Since the start of Covid-19 pandemic, businesses owned by AAPI have been heavily impacted due to misinformation, fear and racism. AAPI Business Collective is a platform for those who wish to support and shop from AAPI businesses.</p>
          <p>AAPI business owners have long faced challenges in America. For decades, Chinese restaurants, laundromats and shops were relegated to Chinatowns, usually less desirable parts of the city. In 1930, a Filipino dance hall in Watsonville was destroyed because of the local’s fear of “mixing between races” (Filipino men dancing with white women). During WWII, when Japanese Americans were incarcerated, many were forced to leave their businesses and homes, and some even sent to internment camps. In 2016, an Indian-owned convenience store was set on fire because the owner was mistaken to be Muslim- Post 9/11 Islamaphobia.</p>
          <span class="image main"><img src="../images/banner.jpg" alt="An AAPI owned store front"/></span>
          <p>While in recent decades, AAPI businesses have fared better, Covid-19 only brought back many of the decades-old racist sentiments and xenophobia surrounding people of API-descent.</p>
          <p>Here, you can find businesses whose owners are AAPI. They are separated into categories. You will be able to read about the businesses and shop through their website. Keep in mind that, when you shop from an AAPI business, you are not only supporting the owner, you are supporting the workers as well.</p>
          <hr>
          <span class="image left"><img src="../images/portrait_ada.jpg" alt="Portrait of co-founder Ada"/></span>
          <p>Ada is a designer and artist based in Brooklyn, NY. She believes that change in the world and in society starts with small actions of individuals. In her spare time, she likes to run, sew and hang out with her fiancé. Ada works on <a href>aapibusiness.co</a> graphic design and UI.</p>
          <span class="image right"><img src="../images/portrait_daniel.jpg" alt="Portrait of co-founder Daniel"/></span>
          <p>Daniel is a software engineer at Square. She is passionate about entrepreneurship and financial empowerment of women. When she’s not coding, she likes running, cycling and spending time with her dog. Daniel works on the <a href>aapibusiness.co</a> back and front end, database and deployment.</p>
          <p><a href="https://www.linkedin.com/in/dannizhao/" class="icon brands style1 fa-linkedin" target="_blank"><span class="label">Linkedin</span></a></p>
          <hr>
          <p>The website <a href>aapibusiness.co</a> runs on a flask and mysql backend and a vue.js frontend.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  name: 'About',
  
  props: {
  }
}
</script>

<style scoped>
.row > .col-8 {
  margin: auto;
}
.row > * {
  padding: 0;
}
</style>