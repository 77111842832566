<template>
  <directory :main-class="'col-12'"/>
</template>

<script>
import Directory from './Directory'
// import DirectoryMobile from './DirectoryMobile'

export default {
  name: 'DirectoryBase',

  components: {
    Directory,
    // DirectoryMobile,
  }
}
</script>