<template>
  <div id="app">
    <div id="wrapper">
      <Header/>
      <router-view/>
      <Footer/>
    </div>
    <navigation/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Navigation from './components/Navigation.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Navigation,
    Footer
  },
}
</script>

<style>
</style>
