import Vue from "vue";
import Vuex from "vuex";
 
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    shop: []
  },
  getters: {},
  mutations: {
    updateShop (state, payload) {
      state.shop = payload
    }
  },
  actions: {}
});